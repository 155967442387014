import mixitup from "mixitup";

const blockLogosScrollReveal = () => {
  ScrollReveal().reveal(".block-logos-slider h2", { delay: 500, duration: 1000 }); // eslint-disable-line
  ScrollReveal().reveal(".block-logos-list", { delay: 800, duration: 1000 }); // eslint-disable-line
};

const blockLogosSwiper = () => {
  const blocks = document.querySelectorAll(".block-logos-list");

  if (blocks.length === 0) {
    return;
  }

  [...blocks].forEach((item) => {
    const swiper = new Swiper(item, { // eslint-disable-line
      // Optional parameters
      autoplay: {
        delay: 2000,
      },
      lazy: true,
      loop: true,
      centeredSlides: false,
      centeredSlidesBounds: true,
      rewind: true,
      passiveListeners: true,
      watchOverflow: true,

      breakpoints: {
        280: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        425: {
          slidesPerView: 3,
          spaceBetween: 16,
        },
        768: {
          slidesPerView: 5,
          spaceBetween: 30,
        },
        1280: {
          slidesPerView: 6,
        },
      },
    });
  });
};

document.addEventListener("DOMContentLoaded", () => {
  blockLogosScrollReveal();

  blockLogosSwiper();

  const mixerEl = document.querySelectorAll(".logos-wrapper");
  if (mixerEl.length > 0) {
    const mixer = mixitup(".logos-wrapper", {
      selectors: {
        target: ".logo",
      },
      animation: {
        duration: 300,
      },
    });

    mixer();
  }
});
